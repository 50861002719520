import React from 'react';

const IconLoader = () => (<svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <title>Loader Logo</title>
  <g>
    <g id="B" transform="translate(35.000000, 72.000000)">
      <path
        d="M 29.878906 -42.53125 L 16.769531 -42.53125 C 14.277344 -42.480469 12.855469 -40.5 12.855469 -38.617188 C 12.855469 -36.941406 13.972656 -35.367188 16.566406 -35.367188 L 21.902344 -35.367188 L 13.871094 -11.789062 C 12.5 -7.824219 10.46875 -6.554688 8.078125 -6.554688 C 7.113281 -6.554688 6.402344 -7.621094 6.402344 -8.078125 C 6.453125 -8.28125 6.453125 -8.535156 6.453125 -8.738281 C 6.453125 -10.722656 4.574219 -11.941406 2.691406 -11.941406 C 0.8125 -11.941406 -1.117188 -10.722656 -1.117188 -7.773438 C -1.117188 -3.101562 3.253906 0.609375 8.839844 0.609375 C 13.109375 0.609375 18.800781 -1.777344 21.492188 -9.554688 L 32.316406 -41.058594 C 32.570312 -41.769531 32.113281 -42.53125 31.300781 -42.53125 Z M 29.878906 -42.53125"
        fill="currentColor"
      />
    </g>
    <path
      stroke="currentColor"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
    />
  </g>
</svg>);

export default IconLoader;
